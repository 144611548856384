@use 'assets/styles/utils/helper' as h;
@use '../sassVariables.module.scss';

.#{sassVariables.$packageOuterClass} {
  .multistoryline__headline {
    color: #000;
    font-size: h.rem(24px);
    line-height: var(--leading-100);
    // margin: h.rem(0 0 8px 0);

    // &.headlineOnly {
    //   margin: h.rem(0 0 16px 0);
    // }
    margin: 0;

    &.noBottomSpace {
      margin: 0;
    }
  }

  .multistoryline__headline.large {
    font-size: h.rem(24px);

    @include h.breakpoint-m {
      font-size: h.rem(28px);
    }
  }

  .multistoryline__headline.lead {
    font-size: h.rem(32px);
  }

  .multistoryline__headline.important {
    font-size: h.rem(32px);

    @include h.breakpoint-m {
      font-size: h.rem(40px);
    }
  }

  .multistoryline__headline.important.large,
  .multistoryline__headline.important.lead {
    font-size: h.rem(32px);

    @include h.breakpoint-m {
      font-size: h.rem(60px);
    }
  }
}
