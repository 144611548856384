@use 'assets/styles/utils/helper' as h;
@use '../sassVariables.module.scss';

/* stylelint-disable
    property-no-unknown,
    at-rule-no-unknown,
    scss/at-rule-no-unknown,
    no-descending-specificity
    --
    Reason: To use containers.
*/
/* stylelint-disable scss/operator-no-newline-before
    --
    Reason: Putting the minus or plus in the first column makes
    it easier to understand the formula (and then simplify).
*/

@mixin topLevelThreeColumnMode {
  &.three-column.vertical-divider-B .columnSeparator-2 {
    display: block;
  }
}

@mixin columnLevelThreeColumnMode {
  --msp-3col-lead-width: .5; // 50%
  &.has-lead-column {
    &.lead-column {
      // flex-basis:
      //   calc(
      //     var(--msp-3col-lead-width)
      //     - ( 2 * var(--msp-column-gap)/2)
      //   );
      flex-basis:
        calc(
          var(--msp-3col-lead-width)
          * (100% - ( 2 * var(--msp-column-gap)))
        );

      &.show-vertical-border-1.one-vertical-border,
      &.show-vertical-border-2.one-vertical-border {
        flex-basis:
          calc(
            var(--msp-3col-lead-width)
            * (100% - var(--msp-3col-1-divider-width))
          );
      }

      &.both-vertical-borders {
        flex-basis:
          calc(
            var(--msp-3col-lead-width)
            * (100% - var(--msp-3col-2-divider-width))
          );
      }
    }

    &.not-lead-column {
      flex-basis:
        calc(
          (1 - var(--msp-3col-lead-width))
          * (100% - 2 * var(--msp-column-gap))
          / 2
        );

      &.show-vertical-border-1.one-vertical-border,
      &.show-vertical-border-2.one-vertical-border {
        flex-basis:
          calc(
            (1 - var(--msp-3col-lead-width)) / 2
            * (100% - var(--msp-3col-1-divider-width))
          );
      }

      &.both-vertical-borders {
        flex-basis:
          calc(
            (1 - var(--msp-3col-lead-width)) / 2
            * (100% - var(--msp-3col-2-divider-width))
          );
      }
    }
  }
}

@mixin columnLevelFullWidthThreeColumnMode {
  --msp-3col-lead-width: .5; // 50%
  &.has-lead-column {
    &.lead-column {
      flex-basis:
        calc(
          var(--msp-3col-lead-width)
          * (100% - (2 * var(--msp-column-gap)))
        );

      @container (min-width: 1160px) {
        &.column-of-items {
          max-width: 560px;

          &.show-vertical-border-1.one-vertical-border,
          &.show-vertical-border-2.one-vertical-border {
            max-width: 540px;
          }

          &.both-vertical-borders {
            max-width: 520px;
          }
        }
      }
      max-width: 440px;

      &.show-vertical-border-1.one-vertical-border,
      &.show-vertical-border-2.one-vertical-border {
        max-width: 420px;
        flex-basis:
          calc(
            var(--msp-3col-lead-width)
            * (100% - var(--msp-3col-1-divider-width))
          );
      }

      &.both-vertical-borders {
        max-width: 400px;
        flex-basis:
          calc(
            var(--msp-3col-lead-width)
            * (100% - var(--msp-3col-2-divider-width))
          );
      }
    }

    &.not-lead-column {
      flex-basis:
        calc(
          (1 - var(--msp-3col-lead-width))
          * (100% - 2 * var(--msp-column-gap))
          / 2
        );
      min-width: 220px;

      @container (min-width: 1160px) {
        min-width: 280px;
      }

      &.show-vertical-border-1.one-vertical-border,
      &.show-vertical-border-2.one-vertical-border {
        flex-basis:
          calc(
            (1 - var(--msp-3col-lead-width)) / 2
            * (100% - var(--msp-3col-1-divider-width))
          );
      }

      &.both-vertical-borders {
        flex-basis:
          calc(
            (1 - var(--msp-3col-lead-width)) / 2
            * (100% - var(--msp-3col-2-divider-width))
          );
      }
    }
  }
}

@mixin columnLevelThirdColumnBottomMode {
  // two columns above third column as row mode

  &.has-lead-column.items-column-3 {
    display: flex;
    flex-flow: row wrap;
    // flex-direction: row;
    // flex-wrap: wrap;
    margin-top: var(--msp-spacing);
    border-top: 1px solid var(--grey-50);
    padding-top: var(--msp-spacing);
    width: 100%;
    flex-grow: 1;
    column-gap: var(--msp-column-gap);

    &.items-count-class-1 .multistory-item,
    &.items-count-is-even .multistory-item,
    &.items-count-is-even.items-count-class-3plus .multistory-item {
      flex-basis: calc((100% - var(--msp-column-gap)) / 2);
      flex-shrink: 0;
      flex-grow: 0;
    }

    &.items-count-class-3plus .multistory-item,
    &.items-count-is-triple .multistory-item,
    &.items-count-is-triple.items-count-is-even .multistory-item {
      flex-basis: calc((100% - 2 * var(--msp-column-gap)) / 3);
      flex-shrink: 0;
      flex-grow: 0;
    }

    .horizontal-item-divider {
      display: none;
    }

    .row-mode-horizontal-divider {
      display: block;
    }

    .column-title {
      width: 100%;
    }
  }
}

.#{sassVariables.$packageOuterClass} {
  .columns-container {
    // Calculated vars
    // item gap divider gap item gap divider gap
    --msp-3col-2-divider-width:
      calc(
        4 * var(--msp-column-gap)
        + 2 * var(--msp-vertical-divider-width)
      );
    // item gap divider gap item
    --msp-3col-1-divider-width:
      calc(
        3 * var(--msp-column-gap)
        + var(--msp-vertical-divider-width)
      );
    --msp-2col-1-divider-width:
      calc(
        2 * var(--msp-column-gap)
        + var(--msp-vertical-divider-width)
      );
    display: flex;
    flex-flow: row wrap;
    // flex-direction: row;
    // flex-wrap: wrap;
    font-family: var(--founders-mono);
    justify-content: space-between;

    --multistory-column-separation: var(--msp-spacing);

    .items-column-1 {
      order: 10;
    }

    .items-column-2 {
      order: 20;
    }

    .items-column-3 {
      order: 30;
    }

    .column-divider-order-1 {
      order: 11;
    }

    .column-divider-order-2 {
      order: 21;
    }

    @include h.breakpoint-s-only {
      margin: 0 var(--msp-spacing);
      flex-flow: column nowrap;

      // This reorders columns without moving dividers
      &.stack-column-2-first {
        .items-column-1 {
          order: 20;
        }

        .items-column-2 {
          order: 10;
        }
      }
    }

    @include h.breakpoint-m {
      &.three-column {
        display: flex;
        width: 100%;
        column-gap: 0; // var(--msp-column-gap);
      }

      &.vertical-divider-A .columnSeparator-1 {
        display: block;
      }
    }

    // this is because third-row-on-bottom in three
    // the second divider only shows on "desktop"
    // Note: this is us trying to control divider and gaps at the container level
    &.cc-zone-layout-fullWidth {
      @container (width >= 920px) {
        @include topLevelThreeColumnMode;
      }
    }

    &.cc-zone-layout-not-fullWidth {
      @container (width >= 860px) {
        @include topLevelThreeColumnMode;
      }
    }

    &.three-column.no-lead-column.vertical-divider-B .columnSeparator-2 {
      display: block;
    }

    .columnSeparator {
      width: 1px;
      margin: 0;
      background-color: var(--grey-50);
      display: none;
    }

    .mobileColumnSeparator {
      height: 1px;
      width: 100%;
      margin: var(--msp-spacing) 0;
      background-color: var(--grey-40);

      @include h.breakpoint-m {
        display: none;
      }
    }

    .multistory-items-column {
      position: relative;

      @include h.breakpoint-s-only {
        flex-basis: calc(100% - var(--msp-spacing));

        &.show-bottom-column-border-mobile {
          padding-bottom: var(--msp-spacing);
          margin-bottom: var(--msp-spacing);
          border-bottom: 1px solid var(--grey-40);
        }
      }

      &.two-column {
        @include h.breakpoint-m {
          --multistory-column-base: 50%;
          --multistory-lead-column-base: 62%;

          &.lead-column {
            --multistory-column-base: var(--multistory-lead-column-base);
          }

          &.not-lead-column {
            --multistory-column-base:
              calc(
                100%
                - var(--multistory-lead-column-base)
              );
          }
          width:
            calc(
              var(--multistory-column-base)
              - (var(--multistory-column-separation) / 2 )
            );

          &.show-vertical-border-1 {
            width:
              calc(
                var(--multistory-column-base)
                - var(--multistory-column-separation)
              );
          }
        }
      }

      .column-title {
        .package-title-top-border {
          @include h.breakpoint-s-only {
            margin: 0;
          }
        }
      }

      /*********************************
          Three Column Specific Behavior
        */
      &.three-column {
        flex-shrink: 0;

        &.no-lead-column {
          flex-basis: calc((100% - (var(--msp-column-gap) * 2)) / 3);

          &.one-vertical-border {
            // This width takes into account gap and divider space to ensure
            // fixed gaps. Flex-flex-flex each div gets (100%-gaps)/3
            flex-basis:
              calc(
                (
                  100%
                  - (
                    (var(--msp-column-gap) * 3)
                    + var(--msp-vertical-divider-width)
                  )
                ) / 3
              );
          }

          &.both-vertical-borders {
            flex-basis:
              calc(
                (
                  100%
                  - (
                    (var(--msp-column-gap) * 4)
                    + (var(--msp-vertical-divider-width) * 2)
                  )
                ) / 3
              );
          }
        }

        /* TwoColumn-over-ColumnThree mode.
              Lead and Not Lead width calculations:

              If there is a lead, then "ThreeColumn" is really two columns,
              and column three becomes a flex row beneath.

              Lead Column gets --msp-3col-lead-width of the space left over
              after the gap and divider widths are taken into account.

              The --msp-3col-lead-width var is a number, a ratio so it can
              be used in the calc, i.e. .58 means 58% of the left over space.
              Lead width is defined in flex-basis for cases where there is
              shared extra space, e.g. three column.
              The widths have to be calculated for each of the various
              possibilities, with or without the vertical dividers that matter.
              I.e. In this mode the second divider isn't important b/c it's
              hidden since it's between column 2 and 3, but there is no column
              three in this TwoColumn-over-ColumnThree mode.

              The lead column calculation is of the form:
                (leadRatio * (100% - gapAndDividerWidth))

              The not-lead column calculation:
                ((1-leadRatio)/2 * (100% - gapAndDividerWidth)
          */
        &.has-lead-column {
          &.lead-column {
            flex-basis:
              calc(
                var(--msp-3col-lead-width)
                * (100% - var(--msp-column-gap))
              );

            &.show-vertical-border-1.one-vertical-border,
            &.both-vertical-borders {
              flex-basis:
                calc(
                  var(--msp-3col-lead-width)
                  * (100% - var(--msp-2col-1-divider-width))
                );
            }
          }

          &.not-lead-column {
            flex-basis:
              calc(
                (1 - var(--msp-3col-lead-width))
                * (100% - var(--msp-column-gap))
              );

            &.show-vertical-border-1.one-vertical-border,
            &.both-vertical-borders {
              flex-basis:
                calc(
                  (1 - var(--msp-3col-lead-width))
                  * (100% - var(--msp-2col-1-divider-width))
                );
            }
          }
        }

        // Three Column Mode w/ Lead only in "desktop" sized container.
        // Works with complementary query below.
        &.coi-zone-layout-fullWidth {
          @container (width >= 920px) {
            @include columnLevelFullWidthThreeColumnMode;
          }
        }

        &.coi-zone-layout-not-fullWidth {
          @container (width >= 860px) {
            @include columnLevelThreeColumnMode;
          }
        }

        @include h.breakpoint-m {
          // Note: This interacts with the complementary (width >= 860)
          // container query above, acting in a mobile first way.
          // Taking care of row-mode in column-3
          &.coi-zone-layout-fullWidth {
            @container (width < 920px) {
              @include columnLevelThirdColumnBottomMode;
            }
          }

          &.coi-zone-layout-not-fullWidth {
            @container (width < 860px) {
              @include columnLevelThirdColumnBottomMode;
            }
          }
        }
      }
    }
  }
}
